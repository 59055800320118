import React from 'react';

import Layout from '@layouts';
import { SEO, TAIKPodcasts } from '@components';

const Index = () => (
  <Layout bannerStyle={{ backgroundImage: 'none' }} hide>
    <SEO
      title='TAIK PODCASTS'
      description='Listen to insightful, engaging and hopeful discussions about the African Continent.'
    />
    <TAIKPodcasts />
  </Layout>
);

export default Index;
